import * as React from "react";
import Router from "next/router";

import { User } from "../types";

type NullableUser = User | null | undefined;

type State = null | {
  user: NullableUser;
};

type ContextState = {
  data: State;
  onToggleEmailActive: (type: string, newValue: string) => void;
  onActivityEvent: (
    type: string,
    route: { path: string; query?: any },
    value?: string
  ) => void;
  changeData: (data: { user: NullableUser }) => void;
};

type Props = {
  children: React.ReactNode;
};

type UserUserType = {
  redirectTo?: string;
  redirectIfFound?: boolean;
};

const AuthContext = React.createContext<ContextState>({
  data: null,
  changeData: () => {},
  onToggleEmailActive: () => {},
  onActivityEvent: () => {},
});

export function AuthContextProvider(props: Props) {
  const { children } = props;
  const [data, setData] = React.useState<State>(null);

  const shouldFetch = !data;

  React.useEffect(() => {
    if (shouldFetch) {
      fetch("/api/user")
        .then((res) => res.json())
        .then(setData)
        .catch(() => setData({ user: null }));
    }
  }, [shouldFetch]);

  function changeData(data: { user: NullableUser }) {
    setData(data);
  }

  async function onToggleEmailActive(listId: string, status: string) {
    console.log(data);
    try {
      setData((prevData) => {
        console.log(prevData);
        return {
          user: {
            ...prevData?.user,
            emails: {
              ...prevData?.user?.emails,
              [listId]: {
                ...prevData?.user?.emails?.[listId],
                status,
              },
            },
          } as NullableUser,
        };
      });

      const emails = data?.user?.emails;
      const hasAccessToNA = data?.user?.permissions["articles-na"].access;

      // Prepare the updated emails object
      const updatedEmails = Object.keys(emails || {}).reduce(
        (acc: any, currentListId) => {
          const email = emails?.[currentListId];
          let emailStatus = email?.status;

          // Override the status for North American emails if there is no access
          if (!hasAccessToNA && email?.name.includes("NA")) {
            emailStatus = "inactive";
          } else if (currentListId === listId) {
            // Apply the user-provided status only if it's not overridden by NA restriction
            emailStatus = status;
          }

          acc[currentListId] = {
            ...email,
            status: emailStatus,
          };
          return acc;
        },
        {}
      );
      await fetch("/api/change-user", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: data?.user?.id,
          emails: updatedEmails,
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function onActivityEvent(
    event: string,
    route: { path: string; query?: any },
    value?: string
  ) {
    try {
      await fetch("/api/change-user", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: data?.user?.id,
          activity: {
            event,
            slug: route.path.split("?")[0],
            value: { query: route.query, data: value || null },
          },
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        changeData,
        onToggleEmailActive,
        onActivityEvent,
        data,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useUser = ({ redirectTo, redirectIfFound }: UserUserType = {}) => {
  const context = React.useContext(AuthContext);

  const data = context.data;
  const user = data?.user;

  const finished = Boolean(data);
  const hasUser = Boolean(user);

  React.useEffect(() => {
    if (!redirectTo || !finished) return;
    if (
      (redirectTo && !redirectIfFound && !hasUser) ||
      (redirectIfFound && hasUser)
    ) {
      Router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, finished, hasUser]);

  return user;
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  return context;
};
